import React from "react"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"
import iconYoutube from "../../static/images/youtube.svg"
import iconLine from "../../static/images/line.svg"
import iconFacebook from "../../static/images/facebook.svg"

const SectionWrapper = styled.div`
  width: 100%;
  padding: 40px 0;
  background: #e5e5e5;
  .socials {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0 0 15px 0;
    padding: 0;
    li {
      padding: 0 8px;
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: transparent;
        border-radius: 100%;
        border: 2px solid #3b4453;
        box-sizing: border-box;
        transition: all 0.25s linear;
        img {
          width: 20px;
          height: 20px;
        }
        &:hover {
          border: 2px solid #3b4453;
        }
      }
    }
  }
  .copyright {
    font-family: "Prompt", sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #323232;
    text-align: center;
    margin-bottom: 0;
  }
`

const Footer = () => {
  return (
    <SectionWrapper>
      <div className="gb-container">
        <Row>
          <Col span={24}>
            <ul className="socials">
              <li>
                <a href={options.youtubeUrl} target="_blank" rel="noreferrer">
                  <img src={iconYoutube} width="20" height="20" alt="Youtube" />
                </a>
              </li>
              <li>
                <a href={options.lineCTA} target="_blank" rel="noreferrer">
                  <img src={iconLine} width="20" height="20" alt="Line" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/MheSolarcell"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={iconFacebook}
                    width="20"
                    height="20"
                    alt="Facebook"
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col span={24}>
            <p className="copyright">
              COPYRIGHT © 2012 สงวนลิขสิทธิ์ ตามพระราชบัญญัติลิขสิทธิ์ พ.ศ. 2539{" "}
              <br className="gb--hidden gb--visible--sm gb--hidden--lg" />
              โดย AEC brand ศูนย์การเรียนรู้ โซล่าเซลล์ เพื่อการเกษตร
            </p>
          </Col>
        </Row>
      </div>
    </SectionWrapper>
  )
}

export default Footer
