import React from "react"
import styled from "@emotion/styled"

const SectionWrapper = styled.div`
  position: relative;
  margin-top: 101px;
  width: 100%;
  height: 48px;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  background: #0699e2;
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  text-align: center;
  @media only screen and (min-width: 768px) {
    margin-top: 0;
  }
`

const HeaderTop = ({ text }) => {
  return <SectionWrapper>{text}</SectionWrapper>
}

export default HeaderTop
