export const options = {
  headerTop: "ปั๊มโซล่าเซลล์ HANDURO ปลีก-ส่ง ทั่วไทย",
  telText: "080-986-9999",
  telCTA: "tel:0809869999",
  lineText: "กดแอดไลน์ @AECbrand",
  lineCTA: " https://line.me/R/ti/p/%40aecbrand",
  youtubeUrl: "https://www.youtube.com/channel/UC6T2t2b2flh7chnSzoLfeMg/videos",
}

export default options
