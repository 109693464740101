import React from "react"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const StyleWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  .col-footercall {
    width: 100%;
  }
  .call-head {
    width: 100%;
    background: #fdfe00;
    padding: 15px;
    text-align: center;
    .head {
      font-family: "Prompt", sans-serif;
      font-size: 26px;
      margin-bottom: 0px;
      font-weight: 700;
      line-height: 1;
      color: #181620;
      a {
        color: #c60300;
        margin-left: 10px;
      }
    }
    .txt-tel {
      font-family: "Prompt", sans-serif;
      font-size: 30px;
      margin-bottom: 0px;
      font-weight: 700;
      line-height: 1.5;
      a {
        color: #000;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    display: none;
  }
  @media only screen and (max-width: 320px) {
    .list {
      .buttom {
        a {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
`

const CallToAction = () => {
  return (
    <StyleWrapper>
      <div>
        <Row>
          <Col className="call-head">
            <h3 className="head">
              โทรสอบถามฟรี!{` `}
              <a href="tel:0809869999" target="_blank" rel="noreferrer">
                คลิกที่นี่
              </a>
            </h3>
            <p className="txt-tel">
              <a href="tel:0809869999" target="_blank" rel="noreferrer">
                TEL.080-986-9999
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </StyleWrapper>
  )
}

export default CallToAction
